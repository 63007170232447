import React from 'react';
import './home.css';
import energyLogo from '../icons/light-bulb.svg';
import tvLogo from '../icons/monitor.svg';
import phoneLogo from '../icons/smartphone.svg';

export default class Home extends React.Component
{
    disclaimer = () => { return( '<div> <p><small>Icons made by <a href="https://www.flaticon.com/authors/vectors-market" title="Vectors Market">Vectors Market</a> from <a href="https://www.flaticon.com/"  title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/"  title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a></small></p></div>') };
    
    

    render() {
        var cssStyle = {
           
        }

        const iconStyle = {
            width: '33%',
            height: '33%',
            display: 'inline-block',
            padding: '10px 6%',
            float: 'left',
            fill: 'red'
        };

        const iconContainer = {
            display: 'inline-block',
            width: '100%',
            textAlign: 'center'
           
        };
        
        const textContainer = {
            display: 'block'
        };

        return (
            <div style={cssStyle}>
                <div style={iconContainer}>
                    <div style={iconStyle} ><img src={energyLogo} alt="Energie" /></div>
                    <div style={iconStyle} ><img src={tvLogo} alt="Energie" /></div>
                    <div style={iconStyle} ><img src={phoneLogo} alt="Energie" /></div>
                </div>
                
                <div style={textContainer}>
                <p>VLIC is de onafhankelijke partner welke leveranciers op het gebied van elektriciteit, 
                    gas, internet, televisie en zonnepanelen vergelijkt.</p>
                <p>VLIC helpt u om een goed en volledig advies aan uw klanten te geven op het gebied van
                     vaste lasten. Zo kunt u bijvoorbeeld bij het tekenen van de definitieve offerte van 
                     een hypotheek ook direct de overige voorzieningen regelen.</p>
                </div>
                
                
            </div>
        );
    }
}

