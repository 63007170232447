import React from 'react';
import './footer.css';

export default class Footer extends React.Component {
    render() {
        return (
            <div className='footer'>
                <div className="footer-container">
                    <div className="footer-column">
                        <p><strong>Contact</strong></p>
                        <p>Ringbaan West 306 B<br />
                        5025VB, Tilburg</p>
                        <p><a href='mailto:info@vlic.nl'>info@vlic.nl</a></p>
                        <p>tel: 020-20 505 03</p>
                    </div>
                    <div className="footer-column">
                        <p><strong>Zakelijke informatie</strong></p>
                        <p>Vaste Lasten Inkoop Combinatie <br />
                            kvk: 60971924</p>
                        <a href="https://vastelastenservice.nl" target="_blank" rel="noopener noreferrer" >Vaste Lasten Service</a>
                    </div>
                </div>
                
            </div>
        );
    }
}