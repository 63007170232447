import React from 'react';

import Menu from './menu';

import Section from './section';

import Home from './home';
import Voorwie from './voorwie';
import Voordelen from './voordelen';
import Footer from './footer';
import OverOns from './overons';
import Contact from './contactform';
import Aanbieders from './aanbieders';


import homeBG from '../img/screen-blur.jpg';
import aboutUsBG from '../img/aboutus-blur.jpg';
import ScrollProgressBar from './scrollprogress';


export default class Main extends React.Component 
{
    render() {
        return (
            <div>
                <ScrollProgressBar />
            <Menu />
            <Section 
                title="Wie is VLIC?"
                bgImage={homeBG}
                id="welkom"
            >
                <Home  />
            </Section>

            <Voordelen />

            <Section 
                title="Voor wie?"
                id="voorwie"
            >
                <Voorwie  />
            </Section>

            <Aanbieders />

            <Section 
                title="Over ons"
                bgImage={aboutUsBG}
                id="overons"
            >
                <OverOns />
            </Section>

            <Section 
                title="Contact"
                id="contact"
            >
                <Contact />
            </Section>

            <Footer />
            </div>
        )
    }
}
    

