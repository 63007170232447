import React from 'react';
import YouTube from 'react-youtube';
import './youtube.css';
 
export default class Video extends React.Component {
  render() {
    const opts = {
      height: this.props.height,
      width: this.props.width,
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 1
      }
    };

    const css = {
        paddingTop: '40px',
        paddingBottom: '40px',
        width: '100%'
    };
 
    return (
        <div style={css}>
            <div className="iframe-container">
            <YouTube
                videoId="2c9IdszYW4o"
                opts={opts}
                onReady={this._onReady}
            />
        </div>
        </div>
        
      
    );
  }
 
  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
}