import React, { Component } from "react";
import "./section.css";

class Section extends Component {
  render() {
    const {title, id, bgImage} = this.props;

    const sectionStyle = {
      minHeight: 'calc(100vh)',
      backgroundColor:  '#333',
      backgroundImage: `url(${bgImage})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '60px',
      paddingTop: '60px',
    };

    const headerStyle = {
      textAlign: 'center', 
      margin: '0 auto',
      width: '80%',
      padding: '20px 20px',
        
    };

    const sectionContentStyle = {
      
      margin: 'auto',
      padding: '20px',
      maxWidth: '780px',
      color: 'white',
    };

    const colorStyle = {
      color: this.props.color
    };

    

    return (
      <div style={sectionStyle} id={id}>
        
        <div>
          <h1 className="section-header" style={headerStyle}>{title}<span style={colorStyle}></span></h1>
        </div>
          
        <div style={sectionContentStyle} >
          {this.props.children}
        </div>
    </div>
    )

  }
}


export default Section;