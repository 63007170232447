import React from 'react';
import './voordelen.css';

export default class Voordelen extends React.Component {
    render() {
        return (
            <div className="col-container">

                <div className="column">
                    <p className="fa fa-plus icon"></p>
                    <p>Bied een toegevoegde waarde aan uw klanten.</p>
                </div>
                <div className="column">
                    <p className="fa fa-thumbs-o-up icon"></p>
                    <p>Meer tevreden klanten.</p>
                </div>
                <div className="column">
                    <p className="fa fa-line-chart icon" ></p>
                    <p>Behaal een grotere omzet uit uw huidige klanten.</p>
                </div>
                <div className="column">
                    <p className="fa fa-commenting icon"></p>
                    <p>Betere service voor uw klanten.</p>
                    
                </div>
            </div>
        );
    }
}