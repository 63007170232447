import React from 'react';
import './aanbieders.css';

import essentImg from '../img/essent-bw.jpg';
import enecoImg from '../img/eneco-bw.jpg';
import greencImg from '../img/greenchoice-bw.jpg';
import kpnImg from '../img/kpn-bw.jpg';
import nemImg from '../img/nem-bw.jpg';
import tele2Img from '../img/tele2-bw.jpg';
import telfortImg from '../img/telfort-bw.jpg';
import tmobileImg from '../img/t-mobile-bw.jpg';
import vodafoneImg from '../img/vodafone-bw.jpg';
import xs4allImg from '../img/xs4all-bw.jpg';



export default class Aanbieders extends React.Component {
    


    render() {

        const images = [ 
            essentImg,
            enecoImg,
            greencImg,
            kpnImg,
            nemImg,
            tele2Img,
            telfortImg,
            tmobileImg,
            vodafoneImg,
            xs4allImg
        ];
        return (
            <div className="grid-container">

            {images.map( image => {
                return (
                    <div className="grid-cell" key={image}> <img src={image} alt={image}></img> </div>
                );
            })}
            </div>
                        
        );
    }
}