import React from 'react';
import Section from './section';
import './vls.css';
import Logo from '../icons/vlic-logo.svg';

import { Link } from 'react-router-dom';

export default class VasteLastenService extends React.Component
{
    
    render() {
        var cssStyle = {
            
        }

        return (
            <div style={cssStyle}>
                <nav
                    className="navi" id="navibar"
                >
                    <div className="navi-content">
                        <Link to='/'> <img src={Logo} className="navi-logo" alt='vlic logo'  /> </Link>
                    </div>
                </nav>
                <Section 
                    title="Vaste Lasten Service"
                    id="vastelastenservice"
                >
                    <h2>Persoonlijke vaste lasten service</h2>
                    <p>Wilt u gebruik maken van de persoonlijke vaste lasten service? U kunt altijd rekenen op een persoonlijk contact, maar het is op basis van de heldere analyse en mogelijkheden bovendien mogelijk om een doorlopende ondersteuning te ontvangen. Sluit in dat geval een abonnement af voor maar €4,58 per maand, waarmee we er als Vaste Lasten Service voor zorgen dat u een veelvoud hiervan kunt besparen. Wat dat betreft kost het lidmaatschap u enkele euro’s per maand, maar kunt u dankzij onze persoonlijke aanpak aan de andere kant rekenen op een enorm voordeel.</p>
                    <p>&nbsp;</p>

                    <h2>Persoonlijk contact</h2>
                    <p>We zorgen er binnen de vaste lasten service voor dat u in ieder geval kunt rekenen op persoonlijk contact. Dat begint al op het moment dat u zich besluit in te schrijven. We nemen dan contact met u op, om een eerste gesprek van 1,5u in te plannen. Dit gesprek gebruiken we om kennis met u te maken, wat te vertellen over wie we zijn en wat we doen en een heldere analyse voor u te maken. Dit is vervolgens het startpunt voor de vaste lasten service, waarmee we ervoor zorgen dat u maximaal op uw vaste lasten kunt gaan besparen.</p>
                    <p>&nbsp;</p>
                    
                    <h2>Heldere analyse en mogelijkheden</h2>
                    <p>De vaste lasten service gaat altijd uit van een heldere analyse van uw huidige situatie, in combinatie met de mogelijkheden die we zien om te besparen. We zorgen er op die manier voor dat het u goed duidelijk wordt wat u op dit moment betaalt en wat wij voor u kunnen betekenen. Is er een meer voordelige energieleverancier beschikbaar of kunt u overstappen naar een goedkopere verzekeraar? Wij zullen u dat binnen de vaste lasten service persoonlijk aangeven, zodat u precies weet waar u aan toe bent en u ons vragen kunt stellen wanneer u dat wenst.</p>
                    <p>&nbsp;</p>

                    <h2>Advies op maat</h2>
                    <p>U ontvangt binnen de vaste lasten service altijd een advies op maat. Ieder gezin is uniek en iedere situatie vraagt daarom om een iets andere aanpak. Over het algemeen kunt u flink besparen op uw energielasten. Bent u bijvoorbeeld al eens overgestapt, of juist nog nooit? Dit leidt tot verschillende situaties, waar we rekening mee kunnen houden dankzij onze persoonlijke aanpak. We zorgen er daarmee voor dat we de vaste lasten service op een uitstekende manier af kunnen stemmen op uw persoonlijke situatie, zodat u maximaal kunt profiteren.</p>
                    <p>&nbsp;</p>

                    <h2>Doorlopende vaste lasten service</h2>
                    <p>Bovendien kunt u dankzij onze persoonlijke aanpak gebruik maken van een doorlopende vaste lasten service. We zorgen er daarmee voor dat we direct tot €500 voor u kunnen besparen, maar we ook daarna op zoek blijven gaan naar mogelijkheden om er meer uit te kunnen halen. Komt er een meer voordelig TV-abonnement beschikbaar, of kunt u de kosten voor het bellen omlaag brengen? U heeft er zelf geen omkijken meer naar, wij houden het voor u in de gaten. We zorgen er op die manier voor dat de vaste lasten service voor u van grote toegevoegde waarde is en u op ons kunt rekenen.</p>
                    <p>&nbsp;</p>

                    <p>Ga naar <a href='https://www.vastelastenservice.nl' target='_blank' rel="noopener noreferrer">vastelastenservice.nl</a> voor meer informatie.</p>
                </Section>
            </div>
        );
    }
}