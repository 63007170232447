import React from 'react';

export default class OverOns extends React.Component
{
    render() {
        var cssStyle = {
            
        }

        return (
            <div style={cssStyle}>
                <p>VLIC is een onafhankelijke partner welke leveranciers op het gebied van elektriciteit, gas, internet, 
                    televisie en zonnepanelen vergelijkt en hierdoor geld bespaart voor de consument. </p>
                <p>VLIC is opgericht door professionals in de financiële dienstverlening, die opmerkten dat er nog heel wat waarde bleef liggen in de markt. 
                    Door hier slim op in te spelen is ons kantoor uitgegroeid tot wat het nu is, mede doordat klanten switchten op basis van deze meerwaarde.</p>
                <p>VLIC heeft medewerkers in dienst die contact onderhouden met de belangrijkste leveranciers waardoor 
                    vragen en problemen van klanten snel kunnen worden opgelost. 
                    VLIC ontvangt een vergoeding van deze leveranciers en deelt deze met haar partners.</p>
            </div>
        );
    }
}
