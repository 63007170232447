import React, { Component } from 'react';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Form, FormGroup, Input } from 'reactstrap';



class Contact extends Component {

    state = {
        name: '',
        email: '',
        company: '',
        phone_nr: '',
        message: '',
        sent: false,
        buttonText: 'Verstuur',
        isCaptchaValid: false
    }

   // Update state after reCaptcha validates visitor
    onCaptchaVerify = (response) => {
        this.setState({
            isCaptchaValid: true
        })
    };

    formSubmit = (e) => {
        e.preventDefault()
      
        //console.log(this.state.isCaptchaValid);

        if (this.state.isCaptchaValid) {
        
            this.setState({
                buttonText: '...bezig'
            })
          
            let data = {
                hash: '2f3743d9662f07976065abfcf7645cbd',
                company: this.state.company,
                phone_nr: this.state.phone_nr,
                name: this.state.name,
                email: this.state.email, 
                message: this.state.message
            }
            
            axios.post('https://nodejs-express.sandervermeer.now.sh/api', data)
            .then( res => {
                this.setState({ sent: true }, this.resetForm());
            })
            .catch( (err) => {
              console.log('Message not sent.. ' + err);
            });    
        }

        
    }

   
    resetForm = () => {
        this.setState({
        name: '',
        email: '',
        company: '',
        phone_nr: '',
        message: '',
        sent: false,
        buttonText: 'Verstuurd',
        isCaptchaValid: false
        })
    }

    

    getForm = () =>  {
        const css = {
            marginTop: '3rem'
        };

        return (
            <Form style={css} onSubmit={ (e) => this.formSubmit(e)}>
                
                <FormGroup>
                    <Input onChange={e => this.setState({ name: e.target.value})} name="name"  type="text" placeholder="Naam" value={this.state.name} />
                </FormGroup>
                <FormGroup>
                    <Input onChange={e => this.setState({ email: e.target.value})} name="email"  type="email" placeholder="Email" value={this.state.email} />
                </FormGroup>
                <FormGroup>
                    <Input onChange={e => this.setState({ company: e.target.value})} name="company"  type="text" placeholder="Bedrijfsnaam" value={this.state.company} />
                </FormGroup>

                <FormGroup>
                    <Input onChange={e => this.setState({ phone_nr: e.target.value})} name="phone_nr"  type="text" placeholder="Telefoonnummer" value={this.state.phone_nr} />
                </FormGroup>

                <FormGroup>
                    <Input type="textarea" onChange={e => this.setState({ message: e.target.value})} name="text" placeholder="Vul hier uw bericht in." value={this.state.message} required />
                </FormGroup>

                <FormGroup>
                    <ReCAPTCHA
                        sitekey="6Ld92nEUAAAAAJw7fbOnV9jwNFR_oVNFJAFVWBB9"
                        onChange={this.onCaptchaVerify}
                    />
                </FormGroup>
                
                <Button disabled={!this.state.isCaptchaValid} type="submit">{this.state.buttonText}</Button>
            </Form>
        );
    }

    render() {
        
        return(
           <div >
               <h5>Wilt u meer weten over wat VLIC voor u kan betekenen? Of hebt u vragen over hoe VLIC werkt? Neem dan contact op, wij staan u graag te woord.</h5>
               <p>Bel ons op 020-20 505 03 of mail ons op <a href="mailto:info@vlic.nl">info@vlic.nl</a>.</p>
               
           </div>
        )
    }
}

export default Contact;


