import React from 'react';
import { Link, animateScroll as scroll } from "react-scroll";
import "./menu.css";
import Logo from '../icons/vlic-logo.svg';


import throttle from 'lodash.throttle';






export default class Menu extends React.Component {

    state = {
        isMobile: false
    }

  

    handleWindowResize = throttle( () => {
        this.setState({ isMobile: window.innerWidth < 480 });
    }, 200 );
    
    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }
   

    scrollToTop = () => {
        scroll.scrollToTop();
    }

    links = [
        {id: 'welkom', text: 'Wie is VLIC?'},
        {id: 'voorwie', text: 'Voor wie?'},
        {id: 'overons', text: 'Over ons'},
        {id: 'contact', text: 'Contact', bold: true}
    ];

    getLink = (id, txt, bold) => {
        let offset = -60;
        let duration = 500;

        if (bold) {
            return (
                <Link
                    activeClass="active"
                    to={id}
                    spy={true} 
                    hashSpy={true}
                    smooth={true}
                    offset={offset}
                    duration={duration}

                    key={id}
                >
                    <strong>{txt}</strong>
                </Link>
                
            );
        } else {
            return (
                <Link
                    activeClass="active"
                    to={id}
                    spy={true} 
                    hashSpy={true}
                    smooth={true}
                    offset={offset}
                    duration={duration}

                    key={id}
                >
                    {txt}
                </Link>
            );
        }
        
    };

    render() {
        return (
            <nav
                className="navi" id="navibar"
            >
                <div className="navi-content">
                    <img src={Logo} className="navi-logo" onClick={this.scrollToTop} alt="vlic logo" />
                    <ul className="navi-items">
                        {
                            this.links.map((link) => {
                                return (
                                    <li className="navi-item" id="blue" key={link.id + link.txt}>
                                    {this.getLink(link.id, link.text, link.bold) }
                                    </li>
                                )
                            })
                        } 
                    </ul>
                    
                </div>
            </nav>
        )
    }
}

