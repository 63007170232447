import React from 'react';

import { Redirect, Switch, Route} from 'react-router-dom'

import './App.css';

import Main from './components/main';
import VasteLastenService from './components/vls';
import fourZeroFour from './components/404';


class App extends React.Component {
 

  render() {
    return (
      
        <div className="App" id="App">
          
          <Switch>
            <Route exact path='/' component={Main}></Route>
            <Route exact path='/vaste-lasten-service' component={VasteLastenService}></Route>
            
            <Redirect from="/contact" to="/#contact" />
            <Redirect from="/overons" to="/#overons" />
            <Redirect from="/voorwie" to="/#voorwie" />

            <Redirect from="/advies" to="/" />

            <Route component={fourZeroFour}></Route>

          </Switch>
          
        </div>
      
    )
  } 
}

export default App;
