import React from 'react';
import Section from './section';
import './vls.css';
import Logo from '../icons/vlic-logo.svg';

import { Link } from 'react-router-dom';


export default class fourZeroFour extends React.Component
{
    render() {
        const containerStyle = {
            height: '80vh'
        };
        const digits_style = {
            fontSize: '20vw', 
            textAlign: 'center',
            color: '#ccc',
            textDecoration: 'none'
        };
        return (
            <div >
                <nav
                    className="navi" id="navibar"
                >
                    <div className="navi-content">
                        <Link to='/'> <img src={Logo} className="navi-logo" alt='vlic logo'  /> </Link>
                    </div>
                </nav>

                <Section 
                    title= 'Oeps!'
                    id='404error'
                    style={containerStyle}
                >
                    <Link to='/'><h1 style={digits_style}>404</h1></Link>
                    <p>Deze pagina bestaat niet. Ga terug naar de <Link to='/'>hoofdpagina</Link>.</p>
                </Section>
            </div>
        )
    }
}