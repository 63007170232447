import React from 'react';
import Video from './youtube';

export default class Voorwie extends React.Component
{
    render() {
        return (
            <div>
                <p><strong>Bent u tussenpersonen op het gebied van hypotheken of verzekeringen?</strong></p>
                <p>VLIC is een onafhankelijke partner welke leveranciers op het gebied van elektriciteit, gas, internet, televisie en (mobiele)telefonie vergelijkt en hierdoor geld bespaard voor de consument.</p>
                <p>VLIC heeft software ontwikkeld die het eenvoudig maakt om de aan te bieden diensten uit te breiden. Zo kan er meer omzet worden gehaald uit bestaande klanten en worden de klanten geholpen door een grotere besparing op hun vaste lasten. Een win-win situatie voor alle betrokken partijen dus. Bekijk de video hiernaast om meer te leren. Interesse? Neem contact op.</p>
                <Video />
            </div>
        );
    }
}
